import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect,
} from "@firebase/auth";
import { firebaseErrors } from "./firebaseErrors";

const googleSignin = () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  provider.setCustomParameters({
    login_hint: "user@example.com",
  });
  signInWithRedirect(auth, provider);
  getRedirectResult(auth)
    .then(() => {
      // console.log("result: ", result);
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const user = result.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      firebaseErrors(errorCode);
      GoogleAuthProvider.credentialFromError(error);
    });
};

export default googleSignin;
