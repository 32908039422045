import router from "../../router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

const firebaseErrors = (errorCode) => {
  switch (errorCode) {
    case "auth/email-already-exists":
      Swal.fire({
        text: "Email already exists.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Go back to Sign in.",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        router.push({ name: "sign-in" });
      });
      break;
    case "auth/user-not-found":
      Swal.fire({
        text: "You don't have an account. Create a new account.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Go to Sign up.",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then(() => {
        router.push({ name: "sign-up" });
      });
      break;
    case "auth/wrong-password":
      Swal.fire({
        text: "Password is incorrect.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Try Again.",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });
      break;
    default:
      Swal.fire({
        text: "An error has occurred.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Try Again.",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });
  }
};

export { firebaseErrors };
